<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<style>
    @font-face {
        font-family: 'Custodia Deco';
        src: url('./fonts/Custodia Deco.ttf');
    }
    @font-face {
        font-family: 'Aubrey Pro';
        src: url('./fonts/AubreyPro.otf');
    }
    @font-face {
        font-family: 'Flores Demo';
        src: url('./fonts/FloresdelSolDemo-Regular.ttf');
    }
    @font-face {
        font-family: 'Lighthaus';
        src: url('./fonts/Lighthaus.otf');
    }
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    body{
        font-family: 'Marmelad', sans-serif;
        background-color: #FAF2E4;
        /* background-color: #FDF6F4; */
    }
    a, button{
        color: #000;
    }
    button{
        font-family: 'Marmelad', sans-serif;
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
    }
</style>
