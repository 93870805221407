<template>
    <div class="banner">
            <div class="banner__inner">
                <div class="banner__content">
                    <h2 class="banner__subheading">14 февраля</h2>
                    <h2 class="banner__heading">Доставим букеты с вашей любовью</h2>
                    <button class="banner__link" @click="selectMarch">Выбрать композицию</button>
                    <!-- <h1 class="banner__heading">ПРИНИМАЕМ заказы<br>НА доставку цветов</h1>
                    <button class="banner__link" @click="selectMarch">Выбрать композицию</button> -->
                    <!-- <h1 class="banner__heading">Принимаем предзаказы на новогодние композиции</h1>
                    <button class="banner__link" @click="selectMarch">Выбрать композицию</button> -->
                    <!-- <p class="banner__notification">при покупке от 3 500 рублей</p> -->
                    <!-- <router-link to="/catalog" class="banner__link">Выбрать букет</router-link> -->
                </div>
            </div>
        </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'SELECT_CATEGORY',
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE'
        ]),
        selectMarch(){
            // this.SELECT_CATEGORY('НОВЫЙ ГОД 25');
            // this.RESET_PAGE_NUMBER();
            // this.RESET_SEARCH_VALUE();
            // this.GET_MORE_PRODUCTS();
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .banner__inner{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 700px;
        color: #fff;
        background: url(../images/14ban.png) no-repeat;
        background-size: cover;
        padding-right: 15px;
        padding-left: 15px;
    }
    .banner__content{
        width: 100%;
        max-width: 1110px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner__subheading{
        font-family: 'Flores Demo';
        font-weight: 400;
        font-size: 128px;
        align-self: flex-end;
    }
    .banner__heading{
        position: relative;
        font-size: 52px;
        font-weight: 400;
        text-align: center;
        line-height: 96%;
        font-family: 'Lighthaus';
        margin-bottom: 120px;
    }
    .banner__heading::after{
        position: absolute;
        content: "";
        height: 37px;
        width: 37px;
        background: url(../images/heart.svg) no-repeat;
        background-size: cover;
        top: -25px;
        right: -25px;
    }
    .banner__link-wrapper{
        background-color: #fff;
        border-radius: 9px;
        transition: all linear .2s;
    }
    .banner__link{
        font-family: 'Cormorant';
        font-size: 35px;
        font-weight: 300;
        padding: 12px 25px;
        line-height: 105%;
        border: 1px solid #fff;
        border-radius: 9px;
        color: #fff;
        text-transform: uppercase;
        transition: all linear .2s;
    }
    .banner__link:hover{
        background-color: rgba(255, 255, 255, .5);
    }
    @media (max-width: 992px){
        
        .banner__heading::after{
            display: none;
        }
    }
    @media (max-width: 768px){
        .banner__heading{
            font-size: 38px;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            background-image: url(../images/14ban-mob.png);
            min-height: auto;
            min-height: 580px;
        }
        .banner__content{
            padding: 10px;
            padding-bottom: 0;
        }
        .banner__heading{
            font-size: 30px;
            max-width: 330px;
            margin-bottom: 0;
            margin-bottom: 180px;
        }
        .banner__subheading{
            font-size: 80px;
            margin-bottom: 20px;
            align-self: center;
        }
        .banner__link{
            font-size: 26px;
            width: 100%;
            padding: 14px 0;
        }
    }
</style>